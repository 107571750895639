a:link, a:visited {
    color: black;
    text-decoration: none;
}

a:hover {
    color: black;
    text-decoration: underline;
}


body {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

img {
    width: 100%;
}

.projects-menu {
    padding-top: 0.5em;
    padding-left: 1em;
}

body {
    padding-top: 1rem;
}

.project-text {
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.project-text p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.indent-text {
    padding-left: 2rem;
}

.block-quote {
    padding-top: 1rem;
    padding-bottom: 2rem;

}
.right-align-text {
    text-align: right;
}

.about-text p{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}